import React from "react";

const Sponsors = () => {

  const [showSpecialBanner, setShowSpecialBanner] = React.useState(false);

  let ads = [
    { Id: 1, DateCreated: '2/06/23', Title: "Become a sponsor!", Detail1: "Place your AD here.", Detail2: "", Detail3: "", Link: "addinfo.pdf", Image: "adboard.jpg" },
    { Id: 2, DateCreated: '2/01/25', Title: "Diamond Wings Supplies", Detail1: "An independent importer of top of the line Belgian pigeon feed,grits and minerals", Detail2: "loft and breeding supplies and much more!", Link: "DIAMONDWINGS.pdf", Image: "" },
    { Id: 3, DateCreated: '01/26/25', Title: "Tommy's Feeds", Detail1: "", Detail2: '', Detail3: "", Link: "TOMMY'S FEED FLYER 2025.pdf", Image: "tommylogo2.jpg" },
    { Id: 5, DateCreated: '01/18/25', Title: "Siegel Pigeons", Detail1: "", Detail2: '', Detail3: "", Link: "https://www.siegelpigeons.com/product/benzing-live-smarthub/", Image: "Benzing Smarthub 5 - SPECIAL-.jpg" },
    { Id: 6, DateCreated: '01/26/25', Title: "CAN'T MISS AUCTIONS & FUTURITY RACES", Detail1: "METUCHEN SHOOTOUT RACE & AUCTION", Detail2: '', Detail3: "QCM FUTURITY RACE & AUCTION", Link: "QCM&SHOOTOUTWEBAD2.pdf", Image: "METQCM2025.JPG" },
    { Id: 11, DateCreated: '01/26/25', Title: "", Detail1: "", Detail2: '', Detail3: "", Link: "https://www.facebook.com/YouCanCOUNTONCARLA", Image: "ReMaxDreams_Carla_Checklist.PNG" },
    { Id: 10, DateCreated: '12/14/24', Title: "Run by a former CJC member!", Detail1: "", Detail2: '', Detail3: "", Link: "https://www.tarheelclassicrace.com/", Image: "tarheellogo2.png" },
    { Id: 15, DateCreated: '01/18/25', Title: "We Design Logos. We Build Websites.", Detail1: "", Detail2: '', Detail3: "", Link: "https://www.charmedcoder.com/", Image: "charmedcoder.png" },
    {Id:16,DateCreated:'03/02/25',Title:"Mulligan's Place Loft: Reduction Sale!", Detail1:"",Detail2:'',Detail3:"", Link:"mulligan.pdf",Image:"mulligan.png"},

  ];

  return (
    <div style={{ fontFamily: "Arial, sans-serif", backgroundColor: "#f9f9f9" }}>
      <header style={{ backgroundColor: "#003366", color: "white", padding: "20px", textAlign: "center" }}>
        <h1>Our Valued Sponsors</h1>
        <p>Thank you to the amazing sponsors who support CJC Combine</p>
      </header>

      <section
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "20px",
          padding: "40px"
        }}
      >
        {ads.map((ad) => (
          <div
            key={ad.Id}
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              textAlign: "center",
              padding: "20px",
              transition: "transform 0.2s"
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <a href={ad.Link} target="_blank" rel="noopener noreferrer">
              {ad.Image && (
                <img
                  src={`../../images/${ad.Image}`}
                  alt={ad.Title || 'Sponsor Ad'}
                  style={{ maxWidth: "300px", maxHeight: "100%", marginBottom: "15px" }}
                />
              )}
              <h3 style={{ fontSize: "16px", margin: "10px 0" }}>{ad.Title}</h3>
              <p style={{ fontSize: "14px", color: "#555" }}>{ad.Detail1}</p>
              <p style={{ fontSize: "14px", color: "#555" }}>{ad.Detail2}</p>
              <p style={{ fontSize: "14px", color: "#555" }}>{ad.Detail3}</p>
            </a>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Sponsors;
